<template>
  <div>
  <app-modal-payment-link-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :payment_link="selectPaymentLink"
    :resendEmailButton="false"
    :showBusinessDetails="isSupportRole"
    @update:payment_link="selectPaymentLink = $event"
  />
  <app-layout class="bg-white">
    <div class="container mx-auto">
      <div class="mx-4 my-5 flex flex-row justify-between items-center">
        <span class="text-xl">{{ $t("support.payment_link_title") }}</span>
      </div>
      <hr class="mx-4 w-full border-b border-gray-200 mb-5" />
      <app-table
        :apiResponse="apiPaginationResponse.data"
        :loading="apiPaginationLoading"
        @params-changed="paramsChanged"
        @on-item-click="onItemClick"
        :filters="[
          {
            key: 'filter[title]',
            type: 'text',
            placeholder: $t('payment.link.header.title'),
          },
          {
            key: 'filter[email]',
            type: 'text',
            placeholder: $t('payment.link.header.email'),
          },
          {
            key: 'filter[currency]',
            type: 'select',
            placeholder: $t('support.currency_placeholder'),
            value: {},
            items: [
              {
                id: 'MYR',
                name: 'MYR',
              },
              {
                id: 'SGD',
                name: 'SGD',
              },
              {
                id: 'BAHT',
                name: 'BAHT',
              },
              {
                id: 'USD',
                name: 'USD',
              },
              {
                id: 'IDR',
                name: 'IDR',
              },
            ],
          }, 
          {
            key: 'filter[amount]',
            type: 'number',
            placeholder: $t('wallet.transaction.sale_amount'),
          },
          {
            key: 'filter[business_name]',
            type: 'text',
            placeholder: $t('payout.table.account.header_business_name'),
          },
          {
            key: 'filter[business_email]',
            type: 'text',
            placeholder: $t('support.business_support_email_placeholder'),
          },
          {
            key: 'filter[payment_link_id]',
            type: 'text',
            placeholder: $t('support.payment_link_id_placeholder'),
          },
          {
            key: 'filter[reference]',
            type: 'text',
            placeholder: $t('support.ref_1_placeholder'),
          },
          {
            key: 'filter[reference_2]',
            type: 'text',
            placeholder: $t('support.ref_2_placeholder'),
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
          },
          {
            key: 'filter[payment_method]',
            type: 'select',
            placeholder: $t('account.transaction.payment_type'),
            value: {},
            items: [
              {
                id: 'Online Banking B2C',
                name: 'Online Banking B2C',
              },
              {
                id: 'Online Banking B2B',
                name: 'Online Banking B2B',
              },
              {
                id: 'Credit / Debit Card',
                name:'Credit/Debit Card'
              },
              {
                id: 'Wallet',
                name:'eWallet'
              }
            ],
          },
        ]"
      >
        <template v-slot:header>
          <th class="font-bold">{{ $t("payment.link.header.sale_amount") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.status") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.system_status") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.title") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.email") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.date") }}</th>
          <th class="font-bold">{{ $t("payment.link.header.payment_link") }}</th>
        </template>
        <template v-slot:body="data">
          <td>
            <p class="font-medium">
              {{
                $formats.currency(data.model.currency, data.model.amount ?? 0.0)
              }}
            </p>
          </td>
          <td class="flex place-items-center space-x-2 justify-left">
            <app-badge
              :status="data.model.paid ? 'success' : 'light'"
              class="w-16 text-xs"
            >
              {{
                data.model.paid
                  ? $t("payment.detail.paid")
                  : $t("payment.detail.unpaid")
              }}
            </app-badge>
          </td>
          <td>
            <app-badge
              v-if="data.model.settlement != null"
              :status="'success'"
              class="w-16 text-xs"
            >
              {{ $t("payment.detail.processed") }}
            </app-badge>
            <p v-else>-</p>
          </td>
          <td>
            {{ data.model.title }}
          </td>
          <td>
            {{ data.model.email }}
          </td>
          <td>
            {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
          </td>
          <td>
            <div class="flex flex-row space-x-1">
              <button @click.stop="copyUrl(data.model)">
                <div
                  class="
                    px-2
                    py-1
                    border
                    rounded-md
                    flex flex-row
                    justify-center
                    bg-background
                    place-items-center
                    hover:bg-gray-100
                  "
                >
                  <app-icon
                    name="ClipboardCopyIcon"
                    class="h-5 w-5 text-gray-400"
                  />
                  <p class="ml-1 text-xs">
                    {{ $t("payment.detail.copy_link") }}
                  </p>
                </div>
              </button>
            </div>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</div>
</template>

<script>
import ROLE from "@/utils/const/role";
export default {
  data() {
    return {
      ROLE: ROLE,
      showModalDetail: false,
      selectPaymentLink: null,
    };
  },

  mounted() {
    this.$store.dispatch(
      "paymentStore/getListPaymentLinksForSupport",
      this.filters
    );
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },

  methods: {
    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    paramsChanged(filters, queryParams) {
      this.$store.dispatch(
        "paymentStore/getListPaymentLinksForSupport",
        queryParams
      );
    },

    isSupportRole() {
      return [ROLE.SUPPORT].includes(this.select_role?.id);
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPaymentLink = data;
    },
  },
};
</script>
